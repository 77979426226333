.blog-img{
    height: auto;
    width: 100%;
    border-radius: 1.5rem !important;
}
.blog-link, .blog-link:hover{
    color: inherit;
    font-weight: 600;
    text-decoration: none;
}
.blog-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
    text-align: justify !important;
}
.blog-text h1{
    font-weight: 600;
}
.blog-text p{
    font-size: 1rem;
    line-height: 1.3rem;
}
.health-text{
    font-style: italic;
}
.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.twentyseven{
    width: 50vw;
    height: auto;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
}