/* .about-text p{
    font-size: 1rem;
    padding-top: 2px;
} */
.medease2-text{
  font-family: 'Fredoka Variable';
  color: #3e3e40;
  padding-bottom: 20vh;
  overflow: hidden;
}

.medease2 p{
  font-size: 1.25rem;
}

.overflow{
  overflow: hidden;
}
.img-active{
  display: none !important;
}
.products-text{
  padding: 60px 20px 0px 20px;
  font-family: 'Fredoka Variable';
  color: #3e3e40;
}

.products-text h1{
  font-size: 3.3rem;
  font-weight: bold;
}

.product-dreamtech p{
  text-align: justify;
  padding: 0% 10%;
  font-size: 100%;
}
.product-paragraph p{
  font-size: 100%;
  text-align: justify;
}
.right p{
  padding-left: 20%;
}
.product-inline{
  display: flex;
  margin-bottom: 20vh;
}
.product-logo{
  width: 40vw;
  height: auto;
}
.container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }

  .image-caption {
    text-align: center;
    margin: 10px;
  }
  .dreamtech-logo{
    margin-top: 10vh;
  }

@media (max-width: 1200px){
  .dreamtech{
    display: inline-block !important;
  }
  .dreamtech-logo{
    margin-top: 0vh;
  }

  .img-active{
    display: block !important;
  }

  .img-inactive{
    display: none !important;
  }
}

@media (max-width: 300px){
  /* .product-inline{
    display: inline-block !important;
  } */
  .product-inline{
    display: flex;
    margin-bottom: 8vh;
  }
  .right p{
    padding-left: 10%;
  }
  .product-paragraph{
    display: inline-block;
  }
  .product-dreamtech{
    text-align: justify;
    padding: 0% 10%;
    font-size: 100%;
  }
  .product-paragraph p{
    font-size: 87%;
    text-align: left!important;
  }
  .product-paragraph, .product-dreamtech{
    margin-top: 8vh !important;
  }
  .product-logo{
    width: 100vw;
    height: auto;
    display: block;
  }
}

@media (min-width: 301px) and (max-width: 700px){
  /* .product-inline{
    display: inline-block !important;
  } */
  .product-inline{
    display: flex;
    margin-bottom: 8vh;
  }
  .right p{
    padding-left: 10%;
  }
  .product-paragraph{
    display: inline-block;
  }
  .product-dreamtech{
    text-align: justify;
    padding: 0% 10%;
    font-size: 100%;
  }
  .product-paragraph p{
    font-size: 87%;
    text-align: left!important;
  }
  .product-paragraph, .product-dreamtech{
    margin-top: 8vh !important;
  }
  .product-logo{
    width: 100vw;
    height: auto;
    display: block;
  }
}
@media (min-width: 701px) and (max-width: 1000px){
  .dreamtech{
    margin-bottom: 8vh;
  }
  .product-paragraph p{
    padding: 0% 15%;
  }
  .product-paragraph{
    display: inline-block;
  }
  /* .product-inline{
    display: inline-block !important;
  } */
  .product-inline{
    display: flex;
    margin-bottom: 8vh;
  }
  .right p{
    padding-left: 20%;
  }
  .product-dreamtech{
    text-align: justify;
    padding: 0% 10%;
    font-size: 100%;
  }
  .product-paragraph p, .product-dreamtech p{
    font-size: 3vw !important;
  }
  .product-paragraph, .product-dreamtech{
    margin-top: 12vh !important;
  }
  .product-logo{
    width: 85vw;
    height: auto;
    display: block;
  }
}
@media (min-width: 1001px) and (max-width: 1200px){
  .dreamtech{
    margin-bottom: 8vh;
  }
  .product-paragraph p{
    padding: 0% 15%;
  }
  .logo-container{
    width: 1000vw !important;
  }
  .product-inline{
    margin-bottom: 15vh;
    display: inline-block !important;
    margin-left: 10% !important;
  }
  .product-dreamtech{
    text-align: justify;
    padding: 0% 10%;
    font-size: 100%;
  }
  .product-paragraph p, .product-dreamtech p{
    font-size: 2vw !important;
  }
  .product-paragraph, .col-logo{
    margin-left: 20%;
  }
  .product-paragraph, .product-dreamtech{
    margin-top: 15vh !important;
  }
  .product-logo{
    width: 60vw;
    height: auto;
  }
}