.blog{
    width: 90% !important;
    height: auto;
    border-radius: 1.5rem;
    background-color: rgba(79,192,232,0.2);
    overflow: hidden;
    position: relative;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .blog{
        width: 60% !important;
        height: auto;
    }
}
button.btn-blog, button.btn-blog:hover, button.btn-blog:active, 
button.btn-blog:active:focus{
    font-family: 'Fredoka Variable';
    font-size: 0.75rem;
    font-weight: 400;
    color: white;
    background-color: #4FC0E8;
    border-radius: 3rem;
    border-color: #4FC0E8;
    padding: 0.10rem 0.75rem 0.10rem 0.75rem;
    float: right;
}
.thumbnail{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    margin: auto;
}
.b-img{
    /* max-height: 25vh;
    max-width: 20vw; */
    max-height: 500%;
    max-width: 34%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blogcard-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}
.blogcard-text h2{
    font-size: 1rem;
    font-weight: bold;
}
.blogcard-text p{
    font-size: 0.75rem;
    line-height: 1rem;
}
.collapse-container{
    margin: 1rem;
}
@media only screen and (min-width: 768px) {
    .collapse-container{
        margin-top: 1rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
  }
  