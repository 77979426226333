.services-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}
.services-text h1{
    font-size: 3.3rem;
    font-weight: bold;
}
.services-text p{
    font-size: 1.25rem;
}
.card{
    height: 300px;
    width: 300px;
    border-radius: 10px;
    padding: 25px 20px 0px 20px;
    border-color: transparent !important;
}
.card-no-padding{
    height: 310px;
    width: 300px;
    border-radius: 10px;
    border-color: transparent !important;
}
.card-text{
    color: white;
}
.card-text h1{
    font-family: 'Fredoka';
    text-shadow: 0.5px 0.5px #000000;
    font-size: 17px;
}
.card-text p{
    font-family: 'Fredoka Variable';
    font-size: 12px;
}
img{
    height: 175px;
    width: auto;
    object-fit: contain;
}
#img-extend{
    height: 203px;
    width: auto;
    object-fit: contain;
}
.card-image{
    width: 300px;
    height: 300px;
}