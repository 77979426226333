.about-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}
.about-text h1{
    font-weight: bold;
    font-size: 3.3rem;
}
.about-text p{
    font-size: 1.2rem;
}
.about-text h2{
    font-size: 2.1rem;
    font-weight: 700;
}
button.btn-careers, button.btn-careers:hover, button.btn-careers:active, 
button.btn-careers:active:focus{
    font-family: 'Fredoka';
    font-size: 2rem;
    color: white;
    background-color: #4FC0E8;
    border-radius: 3rem;
    border-color: #4FC0E8;
    padding: 0.3rem 2.5rem 0.3rem 2.5rem;
}
.old-logo{
    width: 8rem;
}
.new-logo{
    width: 15rem;
}
.logo-box{
    display: block;
    width: auto;
    height: 7rem;
    align-items: center;
}
.carouselContainer{
    max-width: 40rem !important;
}
#groupPictures .carousel-inner{
    border-radius: 0.75rem;
}
#groupPictures .carousel-item img{
    height: auto;
    width: 100%;
}
.overflow{
    overflow-y: hidden !important;
}
.employee-box{
    position: relative;
    width: 100%;
    max-width: 40vw;
    height: auto;
}
.employee-box img{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.72rem !important;
}
.image_overlay{
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	color: #ffffff;
	font-family: 'Fredoka Variable';
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.25s;
    border-radius: 0.72rem !important;
    text-align: center;
}
.accreditations{
    padding-top: 3rem;
    text-align: center;
}
@media (min-width: 301px) and (max-width: 700px){
    .logos{
        width: 45vh;
    }
}
@media (min-width: 201px) and (max-width: 300px){
    .logos{
        width: 40vh;
    }
}
